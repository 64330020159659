import * as React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui';
import type { Document } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';
import { Section, Wide, Narrow } from '../layout';
import type { ThemeT } from '../Theme';
import { BodyRenderer } from '../../cms/BodyRenderer';

interface LayoutT {
  layout?: 'center' | 'narrow' | 'wide' | undefined;
}

export interface BodyPropsT extends LayoutT, ThemeT {
  body?: Document | undefined;
  params?: Options | undefined;
}

function Layout({ layout, ...rest }: LayoutT & BoxT) {
  if (layout === 'center') {
    return (
      <Narrow
        alignItems="center"
        extend={() => ({
          textAlign: 'center',
        })}
        {...rest}
      />
    );
  } else if (layout === 'wide') return <Wide {...rest} />;
  return <Narrow {...rest} />;
}

export function Body({ body, layout = 'narrow', theme, params }: BodyPropsT) {
  return (
    <Section theme={theme}>
      <Layout layout={layout}>
        {body ? BodyRenderer({ document: body, params }) : null}
      </Layout>
    </Section>
  );
}
