// Copied from packages/sfinx/src/renderers/content/components/videoPlayer/index.tsx
// Ref: https://webapps.stackexchange.com/questions/54443/format-for-id-of-youtube-video
interface VideoProps {
  container: HTMLDivElement;
  url: string;
  onPlay: () => void;
  options: Record<string, unknown>;
}

const getYoutubeVideoId = (url: string) => {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[1];
};

const createYoutubeVideo = ({
  container,
  url,
  onPlay,
  options,
}: VideoProps) => {
  const videoId = getYoutubeVideoId(url);
  new window.YT.Player(container, {
    ...options,
    videoId,
    playerVars: {
      playsinline: 1,
    },
    events: {
      onStateChange: ({ data }: { data: string }) =>
        data === window.YT.PlayerState.PLAYING && onPlay(),
    },
  });
};

const youtubeVideoList: VideoProps[] = [];
let youtubeScriptAdded = false;

export const loadYoutubeVideo = (videoProps: VideoProps) => {
  if (window.YT?.Player && window.YT.Player instanceof Function) {
    createYoutubeVideo(videoProps);
    return;
  }

  if (!youtubeScriptAdded) {
    youtubeScriptAdded = true;
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];

    if (!firstScriptTag) return;

    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      youtubeVideoList.forEach((video) => createYoutubeVideo(video));
    };
  }
  youtubeVideoList.push(videoProps);
};

export default loadYoutubeVideo;
