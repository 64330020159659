import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import type { Document } from '@contentful/rich-text-types';
import { Section, Wide, Limit } from '../layout';
import type { TemplateViewT } from '../Template';
import { Template } from '../Template';
import { H2, P } from '../typography';
import { BodyRenderer } from '../../cms/BodyRenderer';
import type { ThemeT } from '../Theme';

export interface TemplatesT extends ThemeT {
  body?: Document | undefined;
  heading?: string;
  templates: Array<TemplateViewT>;
  text?: string;
}

export function Templates({
  body,
  heading,
  templates,
  text,
  theme,
}: TemplatesT) {
  return (
    <Section theme={theme}>
      <Wide alignItems="center">
        {(body || heading) && (
          <Limit
            mb={['space2', 'space8']}
            alignItems="center"
            extend={() => ({
              textAlign: 'center',
            })}
          >
            {body ? (
              <BodyRenderer document={body} />
            ) : (
              <>
                {heading ? (
                  <H2>{heading}</H2>
                ) : (
                  <H2
                    extend={() => ({
                      display: 'none',
                    })}
                  >
                    Browse more resources
                  </H2>
                )}
                {text && <P>{text}</P>}
              </>
            )}
          </Limit>
        )}
        <Box width="100%" flexDirection="row" flexWrap="wrap">
          {templates?.map((t, i) => (
            <Box
              pl={[
                'space0',
                i % 2 ? 'space8' : 0,
                i % 2 ? 'space16' : 'space0',
              ]}
              pr={[
                'space0',
                i % 2 === 0 ? 'space8' : 'space0',
                i % 2 === 0 ? 'space16' : 'space0',
              ]}
              mb="space8"
              width={templates.length > 1 ? [1, 1 / 2] : 1}
              alignItems={templates.length === 1 ? 'center' : 'flex-start'}
              key={t.presentationId}
            >
              <Template {...t} />
            </Box>
          ))}
        </Box>
      </Wide>
    </Section>
  );
}
