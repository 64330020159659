import type { ActionT } from 'src/ui/actions/Action';
import type { IActionFields } from 'types/generated/contentful';

export const linkActionFields = (
  fields: IActionFields | undefined,
): ActionT | undefined =>
  fields
    ? {
        children: fields.text,
        description: fields.description,
        href: fields.href,
        target: fields.newWindow ? '_blank' : '_self',
        rel: fields.newWindow ? 'noopener noreferrer' : '',
        modal: fields.modal,
      }
    : undefined;
