import { Box, Text } from '@mentimeter/ragnar-ui';
import * as React from 'react';
import { Clickable } from './actions';
import type { DecoratedImageT } from './DecoratedImage';
import { DecoratedImage } from './DecoratedImage';
import { Badge, H3 } from './typography';

export interface ArticleT {
  badge: string | undefined;
  heading: string;
  href?: string;
  image: DecoratedImageT | undefined;
  text?: string;
}

export function Article({ badge, heading, href, image, text }: ArticleT) {
  return (
    <Clickable
      href={href}
      key={heading}
      width="100%"
      aria-label="Go to article"
      flexDirection={['column', 'row', 'column']}
      alignItems={['center', 'flex-start', 'center']}
      extend={() => ({
        transition: 'transform 0.3s ease',
        ':hover': {
          opacity: 1,
          transform: 'translateY(-8px)',
        },
      })}
    >
      {image && (
        <Box
          flex="1 1 auto"
          height="100%"
          width={[1, 1 / 2, 1]}
          pr={['space0', 'space4', 'space0']}
        >
          <DecoratedImage
            width="100%"
            src={`${image.src}?w=540&q=60`}
            alt={image.alt}
          />
        </Box>
      )}
      <Box
        flex="1 1 auto"
        width={[1, 1 / 2, 1]}
        pl={['space0', 'space4', 'space0']}
        mt={['space4', 'space0', 'space4']}
      >
        {badge && <Badge mb="space2">{badge}</Badge>}
        <H3 mb={['space1', null, 'space4']}>{heading}</H3>
        {text && <Text>{text}</Text>}
      </Box>
    </Clickable>
  );
}
