'use client';
import * as React from 'react';
import { type ReactNode, type JSX } from 'react';
import type { Document } from '@contentful/rich-text-types';
import type { ImageT, ButtonT, ClickableT } from '@mentimeter/ragnar-ui';
import { Image, Box, Button, Clickable, Text } from '@mentimeter/ragnar-ui';
import { useMatch } from '@mentimeter/ragnar-device';
import { BodyRenderer } from 'src/cms/BodyRenderer';
import { BulletRenderer } from 'src/cms/BulletRenderer';
import type { ThemeT } from 'src/ui/Theme';
import { Section, Limit, Wide } from '../layout';
import { H2, H3, P } from '../typography';
import type { ActionT } from '../actions/Action';
import { BulletMixedContent } from './BulletMixedContent';
import { Carousel } from './carousel/Carousel';

export interface BulletT {
  bullet: Document | undefined;
  image?: ImageT;
  heading: string | undefined;
  text: string | undefined;
  clickable: boolean | undefined;
  action: ActionT | undefined;
}
export interface BulletsT extends ThemeT {
  action: ButtonT | undefined;
  body: Document | undefined;
  bullets: Array<BulletT>;
  heading?: string;
  text?: string;
  variant: 'full' | 'space' | undefined;
}

interface ClickableBulletWrapperProps {
  clickable: boolean | undefined;
  action: ActionT | undefined;
  children?: ReactNode | undefined;
}

export const ClickableBulletWrapper = ({
  action,
  children,
}: ClickableBulletWrapperProps) => {
  return (
    <Clickable
      extend={() => ({
        transition: 'transform 0.3s ease',
        '@media (hover: hover)': {
          ':hover': {
            opacity: 1,
            transform: 'translateY(-8px)',
          },
        },
        ':active': {
          opacity: 1,
        },
      })}
      {...(action as ClickableT)}
    >
      {children}
    </Clickable>
  );
};

export function Bullets({
  action,
  body,
  bullets,
  heading,
  text,
  variant = 'space',
  theme,
}: BulletsT) {
  const isMobile = useMatch({ lessThan: 2 });

  if (isMobile) {
    const content = bullets
      .map(({ bullet, image, heading, text, action, clickable }, index) => {
        let bulletContent;
        if (bullet) {
          bulletContent = <BulletRenderer key={index} document={bullet} />;
        } else if (image) {
          bulletContent = (
            <Image key={index} src={image.src} alt={image.alt} loading="lazy" />
          );
        }

        if (bulletContent || heading || text || action) {
          return (
            <BulletMixedContent
              bulletContent={bulletContent}
              heading={heading}
              text={text}
              action={action}
              clickable={clickable}
              index={index}
              key={(heading || '') + index}
            />
          );
        }
        return null;
      })
      .filter((e) => e !== null) as JSX.Element[];

    return (
      <Section theme={theme} display="flex" px="space0">
        {(body || heading) && (
          <Limit
            mb="space0"
            alignItems="center"
            extend={() => ({
              textAlign: 'center',
            })}
            p="space8"
          >
            {body ? (
              <BodyRenderer document={body} />
            ) : (
              <>
                {heading && <H2>{heading}</H2>}
                {text && <P>{text}</P>}
              </>
            )}
          </Limit>
        )}
        {/* Action above the bullets on mobile view */}
        {action && (
          <Box alignItems="center" mt="space8">
            {action && <Button variant="outline" {...action} />}
          </Box>
        )}
        <Carousel title={heading} content={content} />
      </Section>
    );
  } else {
    return (
      <Section theme={theme} display="flex" px="space0">
        <Wide alignItems="center">
          {(body || heading) && (
            <Limit
              mb="space8"
              maxWidth="40em"
              alignItems="center"
              extend={() => ({
                textAlign: 'center',
              })}
            >
              {body ? (
                <BodyRenderer document={body} />
              ) : (
                <>
                  {heading && <H2>{heading}</H2>}
                  {text && <P>{text}</P>}
                </>
              )}
            </Limit>
          )}
          <Box
            alignItems="stretch"
            mx={['-space2', '-space4', '-space8']}
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            gap="space6"
            width="100%"
            extend={() => ({
              rowGap: '64px',
            })}
          >
            {bullets.map(
              ({ bullet, image, heading, text, action, clickable }, index) => {
                const bulletContent = bullet ? (
                  <BulletRenderer document={bullet} />
                ) : (
                  image && (
                    <Image src={image.src} alt={image.alt} loading="lazy" />
                  )
                );
                return (
                  <Box
                    key={(heading || '') + index + image}
                    alignItems="center"
                    flex="0 0 280px"
                    mb="space0"
                  >
                    <ClickableBulletWrapper
                      clickable={clickable}
                      action={action}
                    >
                      {bulletContent && (
                        <Box width="100%" alignItems="center">
                          <Box
                            alignItems="center"
                            justifyContent="center"
                            extend={({ theme }) => ({
                              width:
                                variant === 'full'
                                  ? '100%'
                                  : `${theme.space[6]}px`,
                              height:
                                variant === 'full'
                                  ? '100%'
                                  : `${theme.space[6]}px`,
                            })}
                          >
                            {bulletContent}
                          </Box>
                        </Box>
                      )}
                      {(heading || text || action) && (
                        <Box
                          mt="space8"
                          alignItems="center"
                          flex="1 1 1"
                          width="100%"
                        >
                          <H3 textAlign="center" mb="space2">
                            {heading}
                          </H3>
                          {text && (
                            <P textAlign="center" mt="space2" mb="space0">
                              {text}
                            </P>
                          )}
                          {action &&
                            (clickable ? (
                              <Text
                                fontWeight="semiBold"
                                lineHeight="inherit"
                                mt="space2"
                                color="primary"
                              >
                                {action.children}
                              </Text>
                            ) : (
                              /// Action below the bullets on tablet and desktop
                              <Button
                                mt="space2"
                                variant="subtle"
                                {...action}
                              />
                            ))}
                        </Box>
                      )}
                    </ClickableBulletWrapper>
                  </Box>
                );
              },
            )}
          </Box>
          {action && (
            // Action below the bullets on tablet and desktop
            <Box alignItems="center" mt="space16">
              {action && <Button variant="outline" {...action} />}
            </Box>
          )}
        </Wide>
      </Section>
    );
  }
}
