import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { fullMentimeterUrl } from '@mentimeter/next-navigation';

const DAYS_10 = 60 * 60 * 24 * 10;

export function getScreenshotUrl({
  presentationId,
  questionId,
  screenshotUrl,
  presentationBaseUrl,
  width = 622,
  height = 350,
  maxAge = DAYS_10,
}: {
  presentationId: string;
  questionId: string;
  screenshotUrl: string;
  presentationBaseUrl?: string;
  width?: number;
  height?: number;
  maxAge?: number;
}) {
  const presentationPath = buildPresentationPath({
    seriesId: presentationId,
    questionId,
    mode: 'screenshot',
  });

  const presentationUrl = presentationBaseUrl
    ? `${presentationBaseUrl}${presentationPath}`
    : fullMentimeterUrl(presentationPath);

  return `${screenshotUrl}?url=${encodeURIComponent(
    presentationUrl,
  )}&maxage=${maxAge}&w=${width}&h=${height}`;
}
